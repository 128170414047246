import {loaderImages} from "@/js/loaderImages.js";
import {menuActive} from "@/js/menuSidebar.js";
import {scrollToForm} from "@/js/scrollToForm.js";
import {animateExpertise} from "@/js/animateExpertise.js";
import {gsapAnimation, scrollAnimation} from "@/js/gsapAnimation.js";

function initFunction() {
    setTimeout(() => {
        animateExpertise();
        const animationsController = gsapAnimation();
        scrollAnimation();

    }, 500);
}

loaderImages(initFunction);
menuActive();
scrollToForm();